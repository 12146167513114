/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./header.css";
import { AppBar, Grid, Drawer, Divider } from "@material-ui/core";
import ChangeModal from "../change-modal/change-modal";
import { setAccountData } from "../../../../services/AccountInfoHandler";
import HeaderApi from "../../services/header-apis";
import { StateContext } from "../../../../services/StateProvider";
import Globalization from "../../../../globalization/index";
import jwt_decode from "jwt-decode";
import OutsideAlerter from "./header-outside";
import ManageAlerter from "./header-manage";
import ProfileAlerter from "./header-profile";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SettingsIcon from '@material-ui/icons/Settings';
const Header = (props) => {
  const oidc = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));
  const decoded = jwt_decode(oidc.access_token);
  const [admin, setAdmin] = useState(false);
  const history = useHistory();
  // GLOBAL ACCOUNT STATE management
  const [globalAccountState, globalAccountDispatch] = useContext(StateContext);

  const globalizationMapping = new Globalization();
  const globalHeaderTexts = globalizationMapping.HEADER;
  const locationPath = useLocation();
  // Handling mobile view MAIN header menu scenario
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const headerApi = new HeaderApi();
  // Handling the account and location information
  const account = globalAccountState.accountInfo.account;
  const location = globalAccountState.accountInfo.location;
  const locationCount = location.length;

  useEffect(() => {
    if (decoded.group && decoded.group.length > 0) {
      const group = decoded.group;

      const index = group.findIndex((x) => x === "EMADMIN");
      if (index >= 0) {
        setAdmin(true);
      }
    }
    headerApi.getUserPreference().then((response) => {
      const model = response.model;
      headerApi.getUserProfile().then((data) => {
        if (data.status === 204) {
          let date = new Date();
          date = date.toISOString();
          const userDetailsCurrent = {
            userId: "",
            firstName: "",
            middleName: "",
            lastName: "",
            userRole: "",
            updatedDate: date,
            phoneNumber: ""
          };
          headerApi.createUser(userDetailsCurrent).then((_data) => {
            globalAccountStateUpdate(model, userDetailsCurrent);
          });
        } else {
          globalAccountStateUpdate(model, data.model);
        }
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDrawerClose = () => {
    // CLOSE ALL POSSIBLE HEADER DRAWERS
    setOpenDrawer(false);
  };
  const globalAccountStateUpdate = (model, userProfile) => {
    const initialAccountState = {
      account: {
        accountName: model.lastAccessedAccountName,
        accountId: model.lastAccessedAccountId
      },
      location: [
        {
          value: model.lastAccessedLocationId,
          label: model.lastAccessedLocationName
        }
      ],
      userSetting: model,
      userProfile
    };
    localStorage.setItem("accountInfo", JSON.stringify(initialAccountState));
    localStorage.setItem("accountInfouserMeasurementSystemValue", JSON.stringify(initialAccountState.userSetting.userMeasurementSystemValue));
    globalAccountDispatch({
      accountInfo: initialAccountState,
      type: "UPDATE"
    });
    let initialAccount = initialAccountState.account;
    let initialLocation = initialAccountState.location;
    if (!(initialAccount || initialAccount.accountId) || initialAccount.accountName === "" || initialLocation.length <= 0 || initialLocation[0].label === "") {
      showChangeModal();
    }
  };
  // Handling manage tires options
  const [showManageDropdown, setManageShowDropdown] = useState(false);
  const onClickManageTires = () => {
    setManageShowDropdown(!showManageDropdown);
    setCreateShowDropdown(false);
    setShowUserProfile(false);
  };

  const [showCreateDropdown, setCreateShowDropdown] = useState(false);
  const onClickCreateOptions = () => {
    setCreateShowDropdown(!showCreateDropdown);
    setManageShowDropdown(false);
    setShowUserProfile(false);
  };

  const [showCreateDropdownMobile, setCreateShowDropdownMobile] = useState(false);
  const onClickCreateOptionsMobile = () => {
    setCreateShowDropdownMobile(!showCreateDropdownMobile);
    setManageShowDropdownMobile(false);
    setShowUserProfile(false);
  };

  const [showManageDropdownMobile, setManageShowDropdownMobile] = useState(false);
  const onClickManageOptionMobile = () => {
    setManageShowDropdownMobile(!showManageDropdownMobile);
    setCreateShowDropdownMobile(false);
    setShowUserProfile(false);
  };

  const onClickOutsideHeader = () => {
    setCreateShowDropdown(false);
  };
  const onClickManageHeader = () => {
    setManageShowDropdown(false);
  };
  const onClickProfileHeader = () => {
    setShowUserProfile(false);
  };
  // Handling user profile options
  const [showUserProfile, setShowUserProfile] = useState(false);
  const onClickUser = () => {
    setCreateShowDropdown(false);
    setManageShowDropdown(false);
    props.onClickHeader("user");
    setShowUserProfile(!showUserProfile);
  };

  const onClickHeaderOptions = (itemClicked) => {
    setCreateShowDropdown(false);
    setManageShowDropdown(false);
    setShowUserProfile(false);
    handleDrawerClose();
    props.onClickHeader(itemClicked);
  };

  const [accountInfo, setAccountInfo] = useState({
    show: false,
    currentPath: locationPath.pathname,
    account: account.accountName,
    location,
    locationCount
  });

  const showChangeModal = () => {
    setCreateShowDropdown(false);
    setManageShowDropdown(false);
    setShowUserProfile(false);
    setAccountInfo({
      show: true,
      currentPath: locationPath.pathname,
      account: accountInfo.account,
      location: accountInfo.location,
      locationCount: accountInfo.locationCount
    });
  };
  const hideChangeModal = () => {
    setAccountInfo({
      show: false,
      currentPath: locationPath.pathname,
      account: accountInfo.account,
      location: accountInfo.location,
      locationCount: accountInfo.locationCount
    });
  };

  // CONFIRMS THE CHANGED ACCOUNT AND LOCATION INFORMATION
  const confirmChangeAccount = (parentAccount, currentLocation, _setLocation) => {
    const currentLocationCount = currentLocation.length;
    // HANDLES THE ACCOUNT INFO STATE LOCALLY IN HEADER
    setAccountInfo({
      show: false,
      currentPath: locationPath.pathname,
      account: parentAccount.accountName,
      location: currentLocation,
      locationCount: currentLocationCount
    });

    // UPDATED ACCOUNT INFORMATION
    const userSetting = globalAccountState.accountInfo;
    const updatedAccountData = {
      account: parentAccount,
      location: currentLocation,
      userSetting: userSetting.userSetting,
      userProfile: userSetting.userProfile
    };

    setAccountData(updatedAccountData);
    // SENDING THE UDPATED STATE OF ACCOUNT INFO TO GLOBAL STATE
    globalAccountDispatch({ accountInfo: updatedAccountData, type: "UPDATE" });
    const req = {
      LastAccessedAccountId: updatedAccountData.account.accountId,
      LastAccessedLocationId: updatedAccountData.location[0].value
    }; 
    // *!* check the header api from multiple -> single location (issue: API only accept single location not array)
    headerApi.updateLastAccessed(req).then((_res) => { console.log("API call successful")});
    // CALL REFRESH FOR SETTING THE UPDATED DATA

    history.push("/home");
  };

  const isUnderHeaderNav = () => {
    switch (props.path) {
      case "reportsDashboard":
      case "scrapSummary":
      case "byRemovalReason":
      case "byCompound":
      case "byIndividualTire":
      case "scrapAnalysis":
      case "byTireType":
      case "byTireSize":
      case "scrapCostByTireSize":
      case "scrapHoursByTireSize":
      case "scrapCostByTireType":
      case "scrapHoursByTireType":
      case "monthlyTireLife":
      case "inServiceTireCostSummary":
      case "tireInstallation":
      case "tireHistory":
      case "byPercentageWorn":
      case "byWearCondition":
      case "byManufacturer":
      case "bySize":
      case "byTiresInServicesTireType":
      case "byVehicleId":
      case "projectedCostByTireSize":
      case "projectedCostByTireType":
      case "byVehicleModel":
      case "byVehicleWheelPosition":
      case "tireForecastByTireType":
      case "tireInflation":
      case "dailyJobSummary":
      case "tireNeedsForecast":
      case "tireRegister":
      case "tiresByDisposition":
      case "removedTire":
      case "tiresSentToRepair":
      case "tiresSentToRetread":
      case "tiresSentToInspectTireReport":
      case "newVSScrap":
      case "achievedHoursReport":
      case "allTireReport":
      case "fleetData":
      case "fleetSurvey":
      case "tireInflationReport":
      case "tireRotation":
      case "tireFlipping":
      case "tireForecast":
      case "rimReport":
      case "rimsInService":
      case "twoAxlesFourTires":
      case "twoAxlesSixTires":
      case "twoAxlesSixTiresTwo":
      case "twoAxlesEightTires":
      case "threeAxlesSixTires":
      case "threeAxlesTenTires":
      case "fourAxlesEightTires":
      case "fiveAxlesTenTires":
      case "fiveAxlesSixteenTires":
      case "fiveAxlesEighteenTires":
      case "fiveAxlesTwentyTires":
      case "sixAxlesTwelveTires":
      case "sevenAxlesFourteenTires":
      case "sevenAxlesTwentySixTires":
      case "eightAxlesSixteenTires":
      case "nineAxlesEighteenTires":
        return "header-title active-header-title"
      default:
        return "header-title"
    }
  }

  const classNameBaseOnPathCreate = () => {
    if (props.path === "create-vehicle" ||
        props.path === "createtire" ||
        props.path === "createparentaccount" ||
        props.path === "create-location" ||
        props.path === "createrim" ) {
      return "header-title-manage active-header-title"
    } else {
      return "header-title-manage"
    }
  }

  const classNameBaseOnPathManage = () => {
    if (props.path === "manage-vehicle" ||
        props.path === "manage-tire" ||
        props.path === "manage-parent-account" ||
        props.path === "manage-location" ||
        props.path === "manage-rim" ||
        props.path === "quick-inspect"||
        props.path === "view-vehicle") {
      return "header-title-manage active-header-title"
    } else {
      return "header-title-manage"
    }
  }

  return (
    <div>
      {/* DESKTOP VIEW  */}

      <div className="desktop-view-header">
        <AppBar position="static">
          <div className="header-container">
            <Grid container justify="space-evenly" wrap="nowrap">
                <Grid container item sm={1} md={1} lg={1} xl={1} justify="center">
                  <div className="header-logo-container">
                    <img src={require("../../../../assets/logo.svg")} className="emtrack-logo" alt="emtrack-logo" />
                  </div>
                </Grid>
              <Grid container item sm={7} md={7} lg={7} xl={7} spacing={1} justify="space-evenly" wrap="nowrap">
                <Grid container item sm={2} md={2} lg={2} xl={2} justify="center">
                  <div onClick={() => onClickHeaderOptions("home")} className={props.path === "home" ? "header-title active-header-title" : "header-title"}>
                    {globalHeaderTexts.HOME}
                  </div>
                </Grid>

                {/* Create Tire Drop-down */}
                <Grid container item sm={2} md={2} lg={2} xl={2} justify="center">
                  <OutsideAlerter onClickOutside={onClickOutsideHeader}>
                    <div className="create-dropdown" onClick={() => onClickCreateOptions()}>
                      <div className="dropdown-icon-container">
                        <div className={classNameBaseOnPathCreate()}>
                          <span>
                            {globalHeaderTexts.CREATE}
                            <KeyboardArrowDownIcon style={{verticalAlign:"middle"}}></KeyboardArrowDownIcon>
                          </span>
                        </div>
                      </div>
                      {showCreateDropdown && (
                        <div className="manage-dropdown-content">
                          <div onClick={() => onClickHeaderOptions("createvehicle")} className="manage-option">
                            {globalHeaderTexts.VEHICLE}
                          </div>
                          <Divider />
                          <div onClick={() => onClickHeaderOptions("createtire")} className="manage-option">
                            {globalHeaderTexts.TIRE}
                          </div>
                          <Divider />
                          <div onClick={() => onClickHeaderOptions("createrim")} className="manage-option">
                            {globalHeaderTexts.RIM}
                          </div>
                          <Divider />
                          <div onClick={() => onClickHeaderOptions("createparentacc")} className="manage-option">
                            {globalHeaderTexts.PARENTACC}
                          </div>
                          <Divider />
                          <div onClick={() => onClickHeaderOptions("createlocation")} className="manage-option">
                            {globalHeaderTexts.LOCATION}
                          </div>
                        </div>
                      )}
                    </div>
                  </OutsideAlerter>
                </Grid>

                <Grid container item sm={2} md={2} lg={2} xl={2} justify="center">
                  <ManageAlerter onClickOutside={onClickManageHeader}>
                    <div className="manage-dropdown" onClick={() => onClickManageTires()}>
                      <div className="dropdown-icon-container">
                        <div className={classNameBaseOnPathManage()}>
                          <span>
                            {globalHeaderTexts.MANAGE}
                            <KeyboardArrowDownIcon style={{verticalAlign:"middle"}}></KeyboardArrowDownIcon>
                          </span>
                        </div>
                      </div>
                      {showManageDropdown && (
                        <div className="manage-dropdown-content">
                          <div onClick={() => onClickHeaderOptions("managevehicle")} className="manage-option">
                            {globalHeaderTexts.MANAGEVEHICLE}
                          </div>
                          <Divider />
                          <div onClick={() => onClickHeaderOptions("managetire")} className="manage-option">
                            {globalHeaderTexts.MANAGETIRE}
                          </div>
                          <Divider />
                          <div onClick={() => onClickHeaderOptions("managerim")} className="manage-option">
                            {globalHeaderTexts.RIM}
                          </div>
                          <Divider />
                          <div onClick={() => onClickHeaderOptions("manageparentaccount")} className="manage-option">
                            {globalHeaderTexts.MANAGEPARENTACCOUNT}
                          </div>
                          <Divider />
                          <div onClick={() => onClickHeaderOptions("managelocation")} className="manage-option">
                            {globalHeaderTexts.MANAGELOCATION}
                          </div>
                          <Divider />
                        </div>
                      )}
                    </div>
                  </ManageAlerter>
                </Grid>
                <Grid item container sm={2} md={2} lg={2} xl={2} justify="center">
                  <div onClick={() => onClickHeaderOptions("reports")} className={isUnderHeaderNav()}>
                    {globalHeaderTexts.REPORTS}
                  </div>
                </Grid>
                <Grid container item sm={1} md={1} lg={1} xl={1} justify="center">
                  <div
                    style={{
                      paddingRight: globalHeaderTexts.HELP === "Aidez-moi" ? 0 : "inherit",
                      paddingLeft: admin === true && globalHeaderTexts.HELP === "Aidez-moi" ? 0 : "inherit"
                    }}
                    onClick={() => onClickHeaderOptions("help")}
                    className={props.path === "help" ? "header-title active-header-title" : "header-title"}
                  >
                    {globalHeaderTexts.HELP}
                  </div>
                </Grid>
                {admin === true && (
                  <Grid container item sm={2} md={2} lg={2} xl={2} justify="center">
                    <div onClick={() => onClickHeaderOptions("admin")} className={props.path === "admin" ? "header-title active-header-title" : "header-title"}>
                      {globalHeaderTexts.ADMIN}
                    </div>
                  </Grid>
                )}
                  <Grid container item sm={2} md={2} lg={2} xl={2} justify="center">
                    <div onClick={() => onClickHeaderOptions("multireportsdata")} className={props.path === "multireportsdata" ? "header-title active-header-title" : "header-title"}>
                    {globalizationMapping.MULTIREPORTS.TITLE}
                  </div>
                </Grid>
              </Grid>

              <Grid container item sm={3} md={3} lg={3} xl={3} wrap="nowrap">
                
                  <Grid container item sm={10} md={10} lg={10} xl={10} style={{justifyContent: "flex-end"}}>
                    <button onClick={() => showChangeModal()} className="change-button">
                      <Grid item sm={12} className="parent-account-container">
                        <span className="account-header-text">{globalHeaderTexts.PARENTACC}: </span>
                        <span className="header-account-info-text">{globalAccountState.accountInfo.account.accountName}</span>
                      </Grid>
                      <Grid item sm={12} className="location-container">
                        <span className="account-header-text">{globalHeaderTexts.LOCATION}: </span>
                        <span className="header-account-info-text">
                          {locationCount === 1 ? location[0].label : globalAccountState.accountInfo.location.length + " " + globalHeaderTexts.LOCATIONSSELECTED}
                        </span>
                      </Grid>
                    </button>                 
                  </Grid>
                  <Grid container item sm={2} md={2} lg={2} xl={2} style={{marginLeft:10}}>
                    <button className="AssignmentInd-button" style={{paddingBottom:5}}>
                      <ProfileAlerter onClickOutside={onClickProfileHeader}>
                        <div className={showUserProfile === true ? "user-desktop-view user-background-clicked" : "user-desktop-view user-background"} onClick={() => onClickUser()}>
                          <SettingsIcon style={{fontSize:33}} className="AssignmentInd"></SettingsIcon >
                          {showUserProfile && (
                            <div className="user-dropdown-content">
                              <div onClick={() => onClickHeaderOptions("myprofile")} className="user-option">
                                {globalHeaderTexts.MYPROFILE}
                              </div>
                              <Divider />
                              {/* <div
                                onClick={() => {
                                  onClickHeaderOptions("editparentaccount");
                                }}
                                className="user-option"
                              >
                                {globalHeaderTexts.EDITPARENTACC}
                              </div>
                              <Divider />
                              <div onClick={() => onClickHeaderOptions("editlocation")} className="user-option">
                                {globalHeaderTexts.EDITLOCATION}
                              </div>
                              <Divider /> */}
                              <div onClick={() => onClickHeaderOptions("userpreference")} className="user-option">
                                {globalHeaderTexts.PREFERENCE}
                              </div>
                              <Divider />
                              <div onClick={() => onClickHeaderOptions("logout")} className="user-option">
                                {globalHeaderTexts.LOGOUT}
                              </div>
                            </div>
                          )}
                        </div>
                      </ProfileAlerter>
                    </button>

                  
                </Grid>
                
              </Grid>
              <Grid container item justify="space-evenly" sm={1} md={1} lg={1} xl={1} wrap="nowrap">
                <div className="header-logo-container">
                  <img src={localStorage.getItem("logoUrl") ? localStorage.getItem("logoUrl") : require("../../../../assets/goodyear-header-logo.svg")} style={{width:'100%', height:'auto', padding:0}} alt="header-logo" />
                </div>
              </Grid>
            </Grid>
          </div>
        </AppBar>
      </div>

      {/* MOBILE VIEW  */}

      <div className="mobile-view-header">
        <div className="mobile-header">
          <div>
            <img src={require("../../../../assets/icons8-menu.svg")} onClick={handleDrawerOpen} className="menu-icon" alt="menu-icon" />
            <Drawer className="drawer" anchor="top" variant="persistent" open={openDrawer}>
              <div className="close-drawer-btn-container">
                <img src={require("../../../../assets/icons8-close-window.png")} onClick={handleDrawerClose} className="header-close-icon" alt="header-close-icon" />
                <Grid container spacing={2} className="mobile-header-logo-container" justify="center">
                  <Grid item sm={6}></Grid>
                  <Grid item sm={2}>
                    <img src={require("../../../../assets/logo.svg")} className="emtrack-logo" alt="emtrack-logo" />
                  </Grid>
                  <Grid item sm={2}>
                    <img src={localStorage.getItem("logoUrl") ? localStorage.getItem("logoUrl") : require("../../../../assets/goodyear-header-logo.svg")} style={{width:'100%', height:'auto', padding:0}} alt="header-logo" />
                  </Grid>
                </Grid>
              </div>

              <div className="user-profile-container">
                <div className="user-title">
                  {globalHeaderTexts.WELCOME} {globalAccountState.accountInfo.userProfile.firstName} {globalAccountState.accountInfo.userProfile.middleName}
                  {"."}
                  {globalAccountState.accountInfo.userProfile.lastName}
                </div>
                <div className="user-role">{globalAccountState.accountInfo.userProfile.userRole}</div>
              </div>
              <Divider />

              <Grid container className="account-container" justify="flex-start">
                <Grid item className="parent-account-container" sm={3}>
                  <div className="account-header-text">{globalHeaderTexts.PARENTACC} : </div>
                  <div className="header-account-info-text">{globalAccountState.accountInfo.account.accountName}</div>
                </Grid>
                <Grid item className="location-container" sm={3}>
                  <div className="account-header-text">{globalHeaderTexts.LOCATION} : </div>
                  <div className="header-account-info-text">
                    {locationCount === 1 ? location[0].label : globalAccountState.accountInfo.location.length + " " + globalHeaderTexts.LOCATIONSSELECTED}
                  </div>
                </Grid>
                <Grid item className="change-button-container" sm={3}>
                  <button className="change-button" onClick={() => showChangeModal()}>
                    {globalHeaderTexts.CHANGE}
                  </button>
                </Grid>
              </Grid>
              <Divider />

              <div onClick={() => onClickHeaderOptions("home")} className={props.path === "home" ? "header-title active-header-title" : "header-title"}>
                {globalHeaderTexts.HOME}
              </div>
              <Divider />

              <div className="create-dropdown" onClick={() => onClickCreateOptionsMobile()}>
                <div
                  className={
                    props.path === "createtire" ||
                    props.path === "createparentacc" ||
                    props.path === "create-vehicle" ||
                    props.path === "createrim" ||
                    props.path === "view-vehicle"
                      ? "dropdown-icon-container active-dropdown-icon-container"
                      : "dropdown-icon-container"
                  }
                >
                  <div className="header-title-manage">{globalHeaderTexts.CREATE}</div>
                  <div className="custom-down-arrow"></div>
                </div>
                {showCreateDropdownMobile && (
                  <div className="create-dropdown-content">
                    <div onClick={() => onClickHeaderOptions("createvehicle")} className="create-option">
                      {globalHeaderTexts.VEHICLE}
                    </div>
                    <Divider />
                    <div onClick={() => onClickHeaderOptions("createtire")} className="create-option">
                      {globalHeaderTexts.TIRE}
                    </div>
                    <Divider />
                    <div onClick={() => onClickHeaderOptions("createparentacc")} className="create-option">
                      {globalHeaderTexts.PARENTACC}
                    </div>
                    <Divider />
                    <div onClick={() => onClickHeaderOptions("createlocation")} className="create-option">
                      {globalHeaderTexts.LOCATION}
                    </div>
                  </div>
                )}
              </div>
              <Divider />

              <div className="manage-dropdown" onClick={() => onClickManageOptionMobile()}>
                <div
                  className={
                    props.path === "manage-vehicle" || props.path === "manage-tire" || props.path === "manage-rim"
                      ? "dropdown-icon-container active-dropdown-icon-container"
                      : "dropdown-icon-container"
                  }
                >
                  <div className="header-title-manage">{globalHeaderTexts.MANAGE}</div>
                  <div className="custom-down-arrow"></div>
                </div>
                {showManageDropdownMobile && (
                  <div className="manage-dropdown-content">
                    <div onClick={() => onClickHeaderOptions("managetire")} className="manage-option">
                      {globalHeaderTexts.TIRE}
                    </div>
                    <Divider />
                    <div onClick={() => onClickHeaderOptions("managevehicle")} className="manage-option">
                      {globalHeaderTexts.MANAGEVEHICLE}
                    </div>
                    <Divider />
                    <div onClick={() => onClickHeaderOptions("managerim")} className="manage-option">
                      {globalHeaderTexts.RIM}
                    </div>
                    <Divider />
                    <div onClick={() => onClickHeaderOptions("manageparentaccount")} className="manage-option">
                      {globalHeaderTexts.MANAGEPARENTACCOUNT}
                    </div>
                    <Divider />
                    <div onClick={() => onClickHeaderOptions("managelocation")} className="manage-option">
                      {globalHeaderTexts.MANAGELOCATION}
                    </div>
                  </div>
                )}
              </div>
              <Divider />
              {/* NOTE : the onClick fn here in mobile view triggers the drawer for reports */}
              <div onClick={() => onClickHeaderOptions("reports")} className={props.path === "reportsDashboard" ? "header-title active-header-title" : "header-title"}>
                {globalHeaderTexts.REPORTS}
              </div>
              <Divider />
              <div onClick={() => onClickHeaderOptions("help")} className={props.path === "help" ? "header-title active-header-title" : "header-title"}>
                {globalHeaderTexts.HELP}
              </div>
              <Divider />
              {admin === true && (
                <div onClick={() => onClickHeaderOptions("admin")} className={props.path === "admin" ? "header-title active-header-title" : "header-title"}>
                  {globalHeaderTexts.ADMIN}
                </div>
              )}
              {admin === true && <Divider />}
              <div onClick={() => onClickHeaderOptions("multireportsdata")} className={props.path === "multireportsdata" ? "header-title active-header-title" : "header-title"}>
                {globalizationMapping.MULTIREPORTS.TITLE}
              </div>
              {admin === true && <Divider />}
              <div onClick={() => onClickHeaderOptions("myprofile")} className={props.path === "myprofile" ? "header-title active-header-title" : "header-title"}>
                {globalHeaderTexts.MYPROFILE}
              </div>
              {/* <Divider />
              <div onClick={() => onClickHeaderOptions("editparentaccount")} className={props.path === "editparentaccount" ? "header-title active-header-title" : "header-title"}>
                {globalHeaderTexts.EDITPARENTACC}
              </div>
              <Divider />
              <div onClick={() => onClickHeaderOptions("editlocation")} className={props.path === "editlocation" ? "header-title active-header-title" : "header-title"}>
                {globalHeaderTexts.EDITLOCATION}
              </div> */}
              <Divider />
              <div onClick={() => onClickHeaderOptions("logout")} className={props.path === "logout" ? "header-title active-header-title" : "header-title"}>
                {globalHeaderTexts.LOGOUT}
              </div>
            </Drawer>
          </div>
          <Grid container spacing={2} justify="center" className="mobile-header-logo-container">
            <Grid item sm={6}></Grid>
            <Grid item sm={2}>
              <img src={require("../../../../assets/logo.svg")} className="emtrack-logo" alt="emtrack-logo" />
            </Grid>
            <Grid item sm={2}>
              <img src={require("../../../../assets/goodyear-header-logo.svg")} className="header-logo" alt="header-logo" />
            </Grid>
          </Grid>
        </div>
      </div>

      <ChangeModal accountInfo={accountInfo} showChangeModal={showChangeModal} hideChangeModal={hideChangeModal} confirmChangeAccount={confirmChangeAccount} />
    </div>
  );
};

export default Header;
